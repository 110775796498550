import React from 'react';
import * as styles from './ProjectStartInput.module.scss';

interface ProjectStartInputProps {
    months: string,
    id: string,
    checked: boolean,
    value: string,
    field: {
        name: string,
        value: string,
        onChange: React.ChangeEventHandler<HTMLInputElement>,
        onBlur: React.FocusEvent<HTMLInputElement>
    }
}

const ProjectStartInput = ({
    checked = false, field, id, value, months,
}: ProjectStartInputProps): JSX.Element => {
    const { name, onChange } = field;

    return (
        <div>
            <input type="radio" id={id} name={name} value={value} onChange={onChange} checked={checked} className={styles.ProjectStartInputField} />
            <label htmlFor={id} className={`text-center ${styles.ProjectStartInput}`}>
                <span className="text-smallcaps">Within</span>
                <div>
                    <span className="text-heading">{months}</span>
                    <span className="text-label block">months</span>
                </div>
            </label>
        </div>
    );
};

export default ProjectStartInput;
