import React, { useEffect } from 'react';

import { navigate } from 'gatsby';

import { Formik, Form, Field } from 'formik';

import * as Yup from 'yup';

import Decorator from '../../Decorator/Decorator';
import Counter from '../../Counter/Counter';
import ProjectStartInput from '../FormInputs/ProjectStartInput/ProjectStartInput';
import ProjectTypeInput from '../FormInputs/ProjectTypeInput/ProjectTypeInput';
import RadioInput from '../FormInputs/RadioInput/RadioInput';
import HorizontalRule from '../../HorizontalRule/HorizontalRule';
import ActionButton from '../../Buttons/ActionButton/ActionButton';
import TextInput from '../FormInputs/TextInput/TextInput';
import TextArea from '../FormInputs/TextArea/TextArea';
import Alert from '../../Alert/Alert';
import IconWarning from '../../Icons/IconWarning';
import FormError from '../FormError/FormError';
import IconCalendar from '../../Icons/IconCalendar';
import IconInfo from '../../Icons/IconInfo';
import { formatSubmission } from './helpers/formatPlannerFormSubmission';
import { PlannerFormStateInterface } from './types/PlannerFormStateInterface';

const formInitialValues = {
    projectLocation: '',
    projectStart: '',
    projectCategory: '',
    projectTypes: [],
    projectTypeOtherInfo: '',
    projectSpec: '',
    furtherInfo: '',
    contactName: '',
    contactEmail: '',
    contactTelephone: '',
    'form-name': 'project-planner',
    'custom-field': '',
} as PlannerFormStateInterface;

const ProjectPlanSchema = Yup.object().shape({
    projectLocation: Yup.string().required('Please let us know whether your project is within Gloucestershire'),
    projectStart: Yup.string().required("Please let us know when you'd like to start your project"),
    projectCategory: Yup.string().required('Please let us know if your project is commercial or residential'),
    projectTypes: Yup.array().min(1, 'Please let us know what type of project you have planned'),
    projectSpec: Yup.string().required('Please let us know if you already have designs for the project'),
    projectTypeOtherInfo: Yup.string().max(750, 'Please keep your message under 750 characters'),
    furtherInfo: Yup.string().max(750, 'Please keep your message under 750 characters'),
    contactName: Yup.string().required('Tell us your name so we can get back to you'),
    contactEmail: Yup.string().email('Please enter a valid email address').required('Please provide an email address so we can get back to you'),
    contactTelephone: Yup.number().integer('Please enter a valid phone number'),
});

const ProjectPlannerForm = (): JSX.Element => {
    const handleSubmit = (values: PlannerFormStateInterface) => {
        fetch('/api/send-contact-form', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: formatSubmission(values)
        })
            .then(() => navigate('/project-submitted'))
            .catch((error) => alert(error));
    };

    return (
        <Formik
            initialValues={formInitialValues}
            onSubmit={handleSubmit}
            validationSchema={ProjectPlanSchema}
        >
            {
                ({
                    values, errors, touched, setFieldValue
                }) => {
                    const {
                        projectLocation, projectStart, projectSpec, projectTypes, projectCategory
                    } = values;

                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    useEffect(() => {
                        setFieldValue('projectTypes', []);
                    }, [projectCategory, setFieldValue]);

                    return (
                        <Form className="contain space-y-6" name="project-planner">
                            <section className="space-y-4 md:space-y-4 md:space-y-5">
                                <div className="space-y-3">
                                    <div className="flex gap-1 items-center">
                                        <Counter label="1" />
                                        <h2 className="text-heading">Is your project in Gloucestershire?</h2>
                                    </div>
                                    {(touched.projectLocation && errors.projectLocation) ? <FormError message={errors.projectLocation} /> : null}
                                </div>
                                <div className="flex gap-3 md:ml-0.75">
                                    <Field
                                        component={RadioInput}
                                        name="projectLocation"
                                        checked={projectLocation === 'yes'}
                                        value="yes"
                                        id="projectLocationYes"
                                        label="Yes"
                                    />
                                    <Field
                                        component={RadioInput}
                                        name="projectLocation"
                                        checked={projectLocation === 'no'}
                                        value="no"
                                        id="projectLocationNo"
                                        label="No"
                                    />
                                </div>
                                {
                                    (projectLocation === 'no')
                                        ? (
                                            <Alert icon={<IconWarning size="24px" />}>
                                                <span className="text-label">We normally only take on projects within Gloucestershire. Please contact us directly to discuss your project further.</span>
                                            </Alert>
                                        )
                                        : null
                                }
                            </section>
                            <section className="space-y-4 md:space-y-4 md:space-y-5">
                                <div className="space-y-3">
                                    <div className="flex gap-1 items-center">
                                        <Counter label="2" />
                                        <h2 className="text-heading">When are you looking to start your project?</h2>
                                    </div>
                                    {(touched.projectStart && errors.projectStart) ? <FormError message={errors.projectStart} /> : null}
                                </div>
                                <div className="project-start-grid">
                                    <Field
                                        component={ProjectStartInput}
                                        name="projectStart"
                                        checked={projectStart === '3-months'}
                                        value="3-months"
                                        id="projectStart3Months"
                                        months="3"
                                    />
                                    <Field
                                        component={ProjectStartInput}
                                        name="projectStart"
                                        checked={projectStart === '6-months'}
                                        value="6-months"
                                        id="projectStart6Months"
                                        months="6"
                                    />
                                    <Field
                                        component={ProjectStartInput}
                                        name="projectStart"
                                        checked={projectStart === '9-months'}
                                        value="9-months"
                                        id="projectStart9Months"
                                        months="9"
                                    />
                                    <Field
                                        component={ProjectStartInput}
                                        name="projectStart"
                                        checked={projectStart === '12-months'}
                                        value="12-months"
                                        id="projectStart12Months"
                                        months="12"
                                    />
                                </div>
                                {
                                    (projectStart === '3-months')
                                        ? (
                                            <Alert icon={<IconCalendar size="24px" />}>
                                                <span className="text-label block">Please be ware we&rsquo;re currently very busy and may not be able to take on larger projects for a few months.</span>
                                            </Alert>
                                        )
                                        : null
                                }
                            </section>
                            <section className="space-y-4 md:space-y-5">
                                <div className="space-y-3">
                                    <div className="flex gap-1 items-center">
                                        <Counter label="3" />
                                        <h2 className="text-heading">What kind of project do you have in mind?</h2>
                                    </div>
                                    {(touched.projectTypes && errors.projectTypes) ? <FormError message={errors.projectTypes} /> : null}
                                </div>
                                <div className="space-y-5 md:ml-3 pl-3 border-0 border-solid border-l-4 border-l-slate-700">
                                    <section className="space-y-3">
                                        <div className="flex gap-1 items-center">
                                            <Counter size="small" label="3a" />
                                            <h3 className="text-subheading">Is your project residential or commercial?</h3>
                                        </div>
                                        <div className="flex gap-3 md:ml-0.5">
                                            <Field
                                                component={RadioInput}
                                                name="projectCategory"
                                                checked={projectCategory === 'residential'}
                                                value="residential"
                                                id="projectCategoryResidential"
                                                label="Residential"
                                            />
                                            <Field
                                                component={RadioInput}
                                                name="projectCategory"
                                                checked={projectCategory === 'commercial'}
                                                value="commercial"
                                                id="projectCategoryCommercial"
                                                label="Commercial"
                                            />
                                        </div>
                                    </section>
                                    <section className="space-y-3">

                                        {
                                            (projectCategory !== '')
                                                ? (
                                                    <div className="flex gap-1 items-center">
                                                        <Counter size="small" label="3b" />
                                                        <h3 className="text-subheading">What type of project is it?</h3>
                                                    </div>
                                                )
                                                : null
                                        }
                                        {
                                            (projectCategory === 'residential')
                                                ? (
                                                    <div className="project-type-grid">
                                                        <Field
                                                            component={ProjectTypeInput}
                                                            name="projectTypes"
                                                            value="residential-new-build"
                                                            id="projectTypeNewBuild"
                                                            iconId="IconNewBuild"
                                                            label="New build"
                                                            checked={!!(projectTypes.includes('residential-new-build'))}
                                                        />
                                                        <Field
                                                            component={ProjectTypeInput}
                                                            name="projectTypes"
                                                            value="residential-full-renovation"
                                                            id="projectTypeFullRenovation"
                                                            iconId="IconRenovation"
                                                            label="Full Renovation"
                                                            checked={!!(projectTypes.includes('residential-full-renovation'))}
                                                        />
                                                        <Field
                                                            component={ProjectTypeInput}
                                                            name="projectTypes"
                                                            value="residential-extension"
                                                            id="projectTypeExtension"
                                                            iconId="IconExtension"
                                                            label="Extension"
                                                            checked={!!(projectTypes.includes('residential-extension'))}
                                                        />
                                                        <Field
                                                            component={ProjectTypeInput}
                                                            name="projectTypes"
                                                            value="residential-loft-conversion"
                                                            id="projectTypeLoftConversion"
                                                            iconId="IconConversion"
                                                            label="Loft Conversion"
                                                            checked={!!(projectTypes.includes('residential-loft-conversion'))}
                                                        />
                                                        <Field
                                                            component={ProjectTypeInput}
                                                            name="projectTypes"
                                                            value="residential-garage-conversion"
                                                            id="projectTypeGarageConversion"
                                                            iconId="IconConversion"
                                                            label="Garage Conversion"
                                                            checked={!!(projectTypes.includes('residential-garage-conversion'))}
                                                        />
                                                        <Field
                                                            component={ProjectTypeInput}
                                                            name="projectTypes"
                                                            value="residential-structural-works"
                                                            id="projectTypeStructuralWorks"
                                                            iconId="IconBlueprint"
                                                            label="Structural Works"
                                                            checked={!!(projectTypes.includes('residential-structural-works'))}
                                                        />
                                                        <Field
                                                            component={ProjectTypeInput}
                                                            name="projectTypes"
                                                            value="residential-other"
                                                            id="projectTypeOther"
                                                            iconId="IconHelp"
                                                            label="Other"
                                                            checked={!!(projectTypes.includes('residential-other'))}
                                                        />
                                                    </div>
                                                )
                                                : null
                                        }
                                        {
                                            (projectCategory === 'commercial')
                                                ? (
                                                    <div className="project-type-grid">
                                                        <Field
                                                            component={ProjectTypeInput}
                                                            name="projectTypes"
                                                            value="commercial-new-build"
                                                            id="projectTypeCommercialNewBuild"
                                                            iconId="IconNewBuild"
                                                            label="New build"
                                                            checked={!!(projectTypes.includes('commercial-new-build'))}
                                                        />
                                                        <Field
                                                            component={ProjectTypeInput}
                                                            name="projectTypes"
                                                            value="commercial-full-renovation"
                                                            id="projectTypeCommercialFullRenovation"
                                                            iconId="IconRenovation"
                                                            label="Refurbishment"
                                                            checked={!!(projectTypes.includes('commercial-full-renovation'))}
                                                        />
                                                        <Field
                                                            component={ProjectTypeInput}
                                                            name="projectTypes"
                                                            value="commercial-extension"
                                                            id="projectTypeCommercialExtension"
                                                            iconId="IconExtension"
                                                            label="Extension"
                                                            checked={!!(projectTypes.includes('commercial-extension'))}
                                                        />
                                                        <Field
                                                            component={ProjectTypeInput}
                                                            name="projectTypes"
                                                            value="commercial-loft-conversion"
                                                            id="projectTypeCommercialLoftConversion"
                                                            iconId="IconConversion"
                                                            label="Conversion"
                                                            checked={!!(projectTypes.includes('commercial-loft-conversion'))}
                                                        />
                                                        <Field
                                                            component={ProjectTypeInput}
                                                            name="projectTypes"
                                                            value="commercial-garage-conversion"
                                                            id="projectTypeCommercialGarageConversion"
                                                            iconId="IconConversion"
                                                            label="Listed properties"
                                                            checked={!!(projectTypes.includes('commercial-garage-conversion'))}
                                                        />
                                                        <Field
                                                            component={ProjectTypeInput}
                                                            name="projectTypes"
                                                            value="commercial-other"
                                                            id="projectTypeCommercialOther"
                                                            iconId="IconHelp"
                                                            label="Other"
                                                            checked={!!(projectTypes.includes('commercial-other'))}
                                                        />
                                                    </div>
                                                )
                                                : null
                                        }
                                    </section>

                                </div>
                                {

                                    (projectTypes.includes('commercial-other') || projectTypes.includes('residential-other'))
                                        ? (
                                            <div className="project-type-other">
                                                <Field
                                                    component={TextArea}
                                                    name="projectTypeOtherInfo"
                                                    id="projectTypeOtherInfo"
                                                    label="Tell us more about your requirements"
                                                />
                                            </div>
                                        )
                                        : null
                                }
                            </section>
                            <section className="space-y-4 md:space-y-5">
                                <div className="space-y-3">
                                    <div className="flex gap-1 items-center">
                                        <Counter label="4" />
                                        <legend className="text-heading">Do you already have technical drawings or design specifications?</legend>
                                    </div>
                                    {(touched.projectSpec && errors.projectSpec) ? <FormError message={errors.projectSpec} /> : null}
                                </div>
                                <div className="flex gap-3 md:ml-0.75">
                                    <Field
                                        component={RadioInput}
                                        name="projectSpec"
                                        checked={projectSpec === 'yes'}
                                        value="yes"
                                        id="projectSpecYes"
                                        label="Yes"
                                    />
                                    <Field
                                        component={RadioInput}
                                        name="projectSpec"
                                        checked={projectSpec === 'no'}
                                        value="no"
                                        id="projectSpecNo"
                                        label="No"
                                    />
                                </div>
                                {
                                    (projectSpec === 'yes')
                                        ? (
                                            <Alert icon={<IconInfo size="24px" />}>
                                                <span className="text-label">Please email any relevant material to office@luxuryconstructions.co.uk</span>
                                            </Alert>
                                        )
                                        : null
                                }
                            </section>
                            <section className="space-y-4 md:space-y-5">
                                <div className="space-y-3">
                                    <div className="flex gap-1 items-center">
                                        <Counter label="5" />
                                        <h2 className="text-heading">Do you have any further information to add? <span className="text-metadata">(optional)</span></h2>
                                    </div>
                                    {(touched.furtherInfo && errors.furtherInfo) ? <FormError message={errors.furtherInfo} /> : null}
                                </div>
                                <div className="space-y-3">
                                    <Field
                                        component={TextArea}
                                        name="furtherInfo"
                                        id="furtherInfo"
                                        label="Further info"
                                    />
                                </div>
                            </section>
                            <section className="space-y-4 md:space-y-5">
                                <div className="space-y-3">
                                    <div className="flex gap-1 items-center">
                                        <Counter label="6" />
                                        <h2 className="text-heading">Let us know how we can get back to you</h2>
                                    </div>
                                    {(touched.contactName && errors.contactName) ? <FormError message={errors.contactName} /> : null}
                                    {(touched.contactEmail && errors.contactEmail) ? <FormError message={errors.contactEmail} /> : null}
                                    {(touched.contactTelephone && errors.contactTelephone) ? <FormError message={errors.contactTelephone} /> : null}
                                </div>
                                <div className="flex flex-wrap gap-3">
                                    <Field
                                        component={TextInput}
                                        type="text"
                                        className="grow"
                                        style={{ flexBasis: '21rem' }}
                                        name="contactName"
                                        id="contactName"
                                        label="Your name"
                                        required
                                    />
                                    <Field
                                        component={TextInput}
                                        type="email"
                                        className="grow"
                                        style={{ flexBasis: '21rem' }}
                                        name="contactEmail"
                                        id="contactEmail"
                                        label="Your email"
                                        required
                                    />
                                    <Field
                                        component={TextInput}
                                        type="tel"
                                        pattern="[789][0-9]{9}"
                                        className="grow"
                                        style={{ flexBasis: '21rem' }}
                                        name="contactTelephone"
                                        id="contactTelephone"
                                        label="Your phone number"
                                    />
                                </div>
                            </section>
                            <HorizontalRule color="bronze-400" />
                            <div className="text-center space-y-5">
                                <ActionButton label="Send your project idea" type="submit" /><br />
                                <Decorator />
                            </div>
                            <input type="hidden" name="form-name" value={values['form-name']} />
                            <input name="new-customer" className="custom-field" />
                        </Form>
                    );
                }
            }
        </Formik>
    );
};

export default ProjectPlannerForm;
