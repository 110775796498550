import { PlannerFormStateInterface } from '../types/PlannerFormStateInterface';

const encode = (data: {[k: string]: any}) => Object.keys(data)
    .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
    )
    .join('&');


/**
 * Have done this this pattern to make it easier to reformat the project forms
 * from the planner form.
 * Any fields that don't need to be renamed will be handled by the ...rest spread operator.
 * @param param0
 * @returns
 */
export const formatSubmission = ({
    projectLocation,
    projectStart,
    projectTypes,
    projectTypeOtherInfo,
    projectSpec,
    furtherInfo,
    ...rest
}: PlannerFormStateInterface): string => {
    const formattedValues = {
        projectInCheltenham: projectLocation,
        projectTimeline: projectStart,
        projectType: projectTypes,
        projectTypeMoreInfo: (projectTypeOtherInfo) || 'N/A',
        projectHasSpec: projectSpec,
        furtherInfo: (furtherInfo) || 'N/A',
        ...rest
    };

    return encode(formattedValues);
};
