import React from 'react';
import * as styles from './TextArea.module.scss';

interface TextAreaProps {
    required?: boolean,
    id: string,
    label: string,
    value: string,
    field: {
        name: string,
        value: string,
        onChange: React.ChangeEventHandler<HTMLTextAreaElement>,
        onBlur: React.FocusEvent<HTMLTextAreaElement>
    }
}

const TextArea = ({
    required = false, field, label, id, value
}: TextAreaProps): JSX.Element => {
    const { name, onChange } = field;

    return (
        <div className="space-y-0.5">
            <label htmlFor={id} className="text-subheading">{label} {(!required) ? <span className="text-metadata">(optional)</span> : null}</label>
            <textarea rows={6} id={id} name={name} value={value} onChange={onChange} className={styles.TextAreaField} />
        </div>
    );
};

export default TextArea;
