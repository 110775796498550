import React from 'react';
import * as styles from './ProjectTypeInput.module.scss';

import IconTypes from '../../../../data/types/IconTypes';

interface ProjectTypeInputProps {
    iconId: string,
    name: string,

    id: string,
    checked: boolean,
    label: string,
    value: string,
    field: {
        name: string,
        value: string,
        onChange: React.ChangeEventHandler<HTMLInputElement>,
        onBlur: React.FocusEvent<HTMLInputElement>
    }
}

const ProjectTypeInput = ({
    iconId, checked = false, field, label, id, value
}: ProjectTypeInputProps): JSX.Element => {
    const IconComponent = IconTypes[iconId];
    const { name, onChange } = field;

    return (
        <div>
            <input type="checkbox" id={id} name={name} value={value} onChange={onChange} checked={checked} className={styles.ProjectTypeInputField} />
            <label htmlFor={id} className={`text-subheading ${styles.ProjectTypeInput}`}>
                <IconComponent size="32px" stroke="slate-400" />
                <span className="text-label block">{label}</span>
            </label>
        </div>
    );
};

export default ProjectTypeInput;
