import React from 'react';
import * as styles from './TextInput.module.scss';

interface TextInputProps {
    required?: boolean,
    type?: 'text'|'email'|'tel',
    id: string,
    className?: string,
    label: string,
    value: string,
    field: {
        name: string,
        value: string,
        onChange: React.ChangeEventHandler<HTMLInputElement>,
        onBlur: React.FocusEvent<HTMLInputElement>
    }
}

const TextInput = ({
    required = false, type = 'text', className, field, label, id, value
}: TextInputProps): JSX.Element => {
    const { name, onChange } = field;

    return (
        <div className={`space-y-0.5 ${className}`}>
            <label htmlFor={id} className="text-subheading">{label} {(!required) ? <span className="text-metadata">(optional)</span> : null}</label>
            <input type={type} id={id} name={name} value={value} onChange={onChange} className={styles.TextInputField} />
        </div>
    );
};

export default TextInput;
