/* eslint-disable react/button-has-type */
// Disabled the above as Typescript ensures we always have a valid button type set

import React, { MouseEventHandler } from 'react';
import * as styles from '../Button.module.scss';
// TODO: Maybe look at refactoring the common bits of this like interface and the iconBefore stuff

const ButtonSizeTypes = {
    small: styles.ButtonSmall,
    medium: styles.ButtonMedium,
};

interface ButtonProps {
    label: string,
    bordered?: boolean,
    onClick?: MouseEventHandler<HTMLButtonElement>,
    icon?: JSX.Element,
    iconPosition?: 'before'|'after',
    type: React.ButtonHTMLAttributes<HTMLButtonElement>['type'],
    size?: keyof typeof ButtonSizeTypes
}

const ActionButton = ({
    label, onClick, bordered = false, icon, iconPosition = 'after', type = 'button', size = 'medium'
}: ButtonProps): JSX.Element => (
    <button onClick={onClick} type={type} className={`valign--center ${styles.Button} ${ButtonSizeTypes[size]} ${(bordered) ? styles.BorderButton : ''}`} title={label}>
        {
            (icon && iconPosition === 'before')
                ? icon
                : null
        }
        {label}
        {
            (icon && iconPosition === 'after')
                ? icon
                : null
        }
    </button>
);

export default ActionButton;
