import React from 'react';
import * as styles from './ContactCallout.module.scss';

import LinkButton from '../Buttons/LinkButton/LinkButton';

const ContactCallout = (): JSX.Element => (
    <section>
        <div className={`contain containMedium ${styles.ContactCallout}`}>
            <div className={styles.ContactCalloutBody}>
                <h3 className="text-heading gold">Need something else?</h3>
            </div>
            <LinkButton href="/contact" label="Get in touch" bordered />
        </div>
    </section>
);

export default ContactCallout;
