import React from 'react';
import * as styles from './Counter.module.scss';

const SizeTypes = {
    default: '',
    small: styles.CounterSmall
};

interface CounterProps {
    className?: string,
    size?: keyof typeof SizeTypes,
    label: string
}

const Counter = ({ className, label = '00', size = 'default' }: CounterProps): JSX.Element => (
    <span className={`text-figure ${styles.Counter} ${SizeTypes[size]} ${className}`}>{label}</span>
);

export default Counter;
