import React from 'react';

import * as styles from './Alert.module.scss';

interface AlertProps {
    icon: React.ReactNode,
    children: React.ReactNode,
}

const Alert = ({ icon, children }: AlertProps): JSX.Element => (
    <div className={styles.Alert}>
        {icon}
        <div className="space-y-1 grow">
            {children}
        </div>
    </div>
);

export default Alert;
