import React from 'react';
import * as styles from './RadioInput.module.scss';

interface RadioInputProps {
    id: string,
    checked: boolean,
    label: string,
    value: string,
    field: {
        name: string,
        // value: string,
        onChange: React.ChangeEventHandler<HTMLInputElement>,
        onBlur: React.FocusEvent<HTMLInputElement>
    }
}

// TODO: wierd thing one; I don't think you should have to manually pass in the current value for the field
// TODO: wierd thing to; When value is passed as part of field, it gets overwritten by the intialValues. Why?

const RadioInput = ({
    checked = false, field, label, id, value
}: RadioInputProps): JSX.Element => {
    const { name, onChange } = field;

    return (
        <div>
            <input type="radio" id={id} name={name} value={value} onChange={onChange} checked={checked} className={styles.RadioInputField} />
            <label htmlFor={id} className={`text-subheading ${styles.RadioInputLabel}`}>{label}</label>
        </div>
    );
};

export default RadioInput;
