import React from 'react';
import IconWarning from '../../Icons/IconWarning';

interface FormErrorProps {
    message: string|string[]
}

const FormError = ({ message }: FormErrorProps): JSX.Element => (
    <div className="flex items-center gap-1">
        <IconWarning stroke="plum" size="32px" />
        <span className="text-plum">{message}</span>
    </div>
);

export default FormError;
