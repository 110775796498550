import React, { Fragment } from 'react';

import Decorator from '../components/Decorator/Decorator';
import Hero from '../components/Hero/Hero';
import ContactCallout from '../components/ContactCallout/ContactCallout';
import Seo from '../components/Seo/Seo';
import ProjectPlannerForm from '../components/Forms/ProjectPlannerForm/ProjectPlannerForm';
import CarbonCallout from '../components/CarbonCallout/CarbonCallout';

const ProjectPlanner = (): JSX.Element => (
    <Fragment>
        <Seo title="Project planner" />
        <Hero height="small">
            <Hero.Body>
                <div className="text-center contain containSmall space-y-1">
                    <Hero.Title className="gold">Let's start your next project</Hero.Title>
                    { /* <Hero.Subtitle className="m-auto">Quia aut quidem et nulla sunt. Necessitatibus perferendis eos veritatis non sint suscipit quos voluptatum. Nisi minima tempore impedit.</Hero.Subtitle> */ }
                </div>
            </Hero.Body>
        </Hero>
        <div className="text-center mb-6 md:mb-7">
            <Decorator />
        </div>
        <ProjectPlannerForm />
        <ContactCallout />
        <CarbonCallout />
    </Fragment>
);

export default ProjectPlanner;
